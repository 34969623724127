<template>
  <div v-if="loadedPage" class="participation-description">
    <div class="participation-image-wrapper">
      <div
        :class="{
          rotate90: rotate == 1,
          rotate180: rotate == 2,
          rotate270: rotate == 3,
        }">
      </div>
      <div class="button-wrapper">
        <button
          class="participation-btn"
          @click="rotate <= 2 ? (rotate += 1) : (rotate = 0)">
          rotate90
        </button>
        <button
          v-if="admin" @click="showEditModal = !showEditModal"
          class="participation-btn">
          Edit participation
        </button>
        <span
          v-if="participation?.comments?.length"
          class="participation-flag">
          Comments {{
            participation.comments && participation.comments.length > 0
              ? `(${participation.comments.length})`
              : ""
          }}
        </span>
        <button
          v-if="admin"
          class="participation-btn"
          disabled
          @click="tryConsumerFlow()">
          Try
        </button>
        <button
          v-if="admin"
          class="participation-btn"
          @click="deleteModal = true">
          Delete
        </button>
        <a
          v-if="admin"
          class="participation-btn"
          :href="`${windowOrigin}/admin/games/${game.game_type}/${game._id}`">
          Goto Game
        </a>
      </div>
    </div>
    <div class="participation-status">
      <div class="pv-breadcrumb">
        <div class="pv-breadcrumb__container">
          <!--<span v-if="game.participation_step" class="pv-breadcrumb__item" :class="{'visited' : stepNumber > 0, 'current' : stepNumber == 0 }">
            <div class="pv-breadcrumb__title">PV</div>
          </span>
          <span v-if="game.registration_step" class="pv-breadcrumb__item" :class="{'visited' : stepNumber > 1, 'current' : stepNumber == 1 }">
            <div class="pv-breadcrumb__title">PR</div>
          </span>
          <span v-if="game.survey_step" class="pv-breadcrumb__item" :class="{'visited' : stepNumber > 2, 'current' : stepNumber == 2 }">
            <div class="pv-breadcrumb__title">Survey</div>
          </span>
          <span v-if="game.address_step" class="pv-breadcrumb__item" :class="{'visited' : stepNumber > 3, 'current' : stepNumber == 3 }">
            <div class="pv-breadcrumb__title">Address</div>
          </span>
          <span v-if="game.review_step" class="pv-breadcrumb__item" :class="{'visited' : stepNumber > 4, 'current' : stepNumber == 4 }">
            <div class="pv-breadcrumb__title">Review</div>
          </span>
          <span v-if="game.cashback_step" class="pv-breadcrumb__item" :class="{'visited' : stepNumber > 5, 'current' : stepNumber == 5 }">
            <div class="pv-breadcrumb__title">Cashback</div>
          </span>
          <span class="pv-breadcrumb__item" :class="{ 'visited' : stepNumber == 6 }">
            <div class="pv-breadcrumb__title">Done</div>
          </span>-->
        </div>

        <div
          v-if="!participation.cta_done && participation.participation_issue && participation.participation_issue != 'none'"
          style="color: red; font-weight: bold;">{{ participation.participation_issue }}</div>

        <div
          v-if="!game.public"
          style="color: red; font-weight: bold;">The game is not public!</div>
      </div>

      <Collapsable :collapsed="false" :header="{bgColor: '#F9D7B3', textPosition: 'left'}">
        <template v-slot:header>
          <h3>Participation details</h3>
        </template>

        <template v-slot:content>
          <div class="collapsable-body">
            <div
              v-if="participation.status"
              style="margin: 10px 0; font-weight: bold;">
              <span>Status: </span><span style="color: blue;">{{ participation.status }}</span>
            </div>

            <div class="header"><b>Name:</b> <span class="select-whole-text">{{ participation.user.name }}</span> <span class="select-whole-text">{{ participation.last_name }}</span></div>
            <div class="header"><b>Phone nr:</b> <span class="select-whole-text">{{ participation.user.phone_nr }}</span></div>
            <div class="header"><b>Email:</b> <span class="select-whole-text">{{ participation.user.email }}</span></div>

            <div v-if="game.game_type == 'QrHunt'" class="header"><b>Points:</b> {{ participation.points }}</div>
            <div v-if="game.game_type == 'LoyaltyShop'" class="header"><b>Points:</b> {{ participation.points_balance }}</div>

            <!--<div v-if="participation.running_analyzer" class="header" style="color: red;"><b>running_analyzer!</b></div>-->

            <div v-if="game.survey_step" class="header">
              <div class="divider"></div>
              <h3>Survey</h3>
              <div class="collapsable-bodys">
                <div
                  v-for="question in participation.survey"
                  :key="question._id"
                  class="select-whole-text">
                  <div
                    v-if="question.upload && question.media?.url"
                    style="display: flex;">
                    <b>{{ question.label }}: </b>
                    <img
                      style="width: 200px; height: 200px;"
                      :src="question.media.url" alt="placeholder">
                  </div>
                  <div v-else>
                    <b>{{ question.label }}: </b>{{ question.value }}
                  </div>
                </div>
              </div>
            </div>

            <div class="header">
              <div class="divider"></div>
              <h3>Address</h3>
              <div><b>Address: </b>{{ participation.user.adress }}</div>
              <div><b>Postal code: </b>{{ participation.user.postal_code }}</div>
              <div><b>City: </b>{{ participation.user.city }}</div>
            </div>

          </div>
        </template>
      </Collapsable>

      <Collapsable :collapsed="false" :header="{bgColor: '#F9D7B3', textPosition: 'left'}">
        <template v-slot:header>
          <h3>Game details</h3>
        </template>

        <template v-slot:content>
          <div class="collapsable-body">
            <div class="header"><b>Name:</b> {{ game.name }}</div>
            <div class="header"><b>Game type:</b> {{ game.game_type }}</div>
            <div v-if="game.starts_at || game.expires_at">
              <h3>Game period</h3>
              {{ formatDate(game.starts_at) }}
              - {{ formatDate(game.expires_at) }}</div>
          </div>
        </template>
      </Collapsable>

      <Collapsable :collapsed="false" :header="{bgColor: '#F9D7B3', textPosition: 'left'}">
        <template v-slot:header>
          <h3>Claims</h3>
        </template>

        <template v-slot:content>
          <div
            v-for="claim in participation.claims"
            :key="claim._id"
            class="comment"
            style="display: flex; flex-direction: column;">
            <span class="date">Claimed at: {{ formatDate(claim.claimed_at) }}</span>
            <span>{{ claim.prize?.prize_type }}</span>
            {{ claim }}
          </div>
        </template>
      </Collapsable>

      <Collapsable
        :collapsed="true"
        :header="{bgColor: !fe_participation?.comments.length ? '#F9D7B3' : '#EA7801', textPosition: 'left'}">
        <template v-slot:header>
          <h3>Comments ({{ sortedComments.length }})</h3>
        </template>

        <template v-slot:content>
          <div class="collapsable-body">
            <div style="display: flex; flex-direction: row;">
              <inputField
                style="margin-right: 10px;"
                label="Write comment"
                v-model="newComment"/>
              <div
                style="display: flex; flex-direction: column; justify-content: end; margin-bottom: 20px;">
                <button
                  class="btn btn-activate"
                  @click="addAdminGameParticipationComment()"
                  :disabled="newComment.length < 3">
                  Save
                </button>
              </div>
            </div>

            <div
              v-for="comment in sortedComments"
              :key="comment._id"
              class="comment"
              style="display: flex; flex-direction: column;">
              <span class="date"> {{ formatDate(comment.created_at) }} </span>
              <span>{{ comment.user?.name }} {{ comment.user?.last_name }}</span>
              {{ comment.comment }}
            </div>
          </div>
        </template>
      </Collapsable>

      <Collapsable
        v-if="admin"
        :collapsed="true"
        :header="{bgColor: '#F9D7B3', textPosition: 'left'}">
        <template v-slot:header>
          <h3>JSON</h3>
        </template>

        <template v-slot:content>
          <div class="collapsable-body">
            <pre class="participation-metadata" id="json"></pre>
          </div>
        </template>
      </Collapsable>
    </div>

    <modal
      v-if="showEditModal"
      @close="showEditModal = false">
      <h3>Edit participation</h3>
      <div style="max-width: 600px;">
        <p>Edit and override these fields</p>
        <div style="display: flex; flex-direction: column;">
          <div v-if="game.game_type == 'LoyaltyShop'">
            <div>Points: {{ participation.points_balance }}</div>
            <inputField
              v-model="editForm.addPoints"
              style="margin: 5px 0;"
              label="Add Points"/>
          </div>
        </div>
      </div>
      <div>
        <button
          class="btn btn-transparent"
          @click="showEditModal = !showEditModal">
          Close
        </button>
        <button
          class="btn btn-transparent"
          @click="onEditConfirm">
          Save
        </button>
      </div>
    </modal>

    <modal
      v-if="deleteModal"
      @close="deleteModal = false">
      <h3>Du you really want to delete the participation?</h3>
      <p v-if="participation.cta_done">The participation has CTA_DONE, deleting the participation means that the consumer can get another Prize</p>
      <p v-else>The participation is not yet done with all the steps</p>
      <div>
        <button class="btn btn-transparent" @click="deleteModal = false">Cancel</button>
        <button class="btn btn-activate" @click="deleteParticipation()">Delete</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import InputField from '../InputField/InputField.vue';
import Collapsable from '../Collapsable/Collapsable.vue';

export default {
  name: 'Participation',
  components: {
    InputField,
    Collapsable,
  },
  props: {
    participationId: {
      type: String,
      required: true,
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getAdminParticipation();
  },
  computed: {
    ...mapGetters('games', ['participation', 'participations']),
    userType() {
      return this.admin ? 'Admin' : 'Company';
    },
    userApi() {
      return this.admin ? 'admin' : 'c';
    },
    game() {
      return this.participation?.game;
    },
    sortedComments() {
      return this.fe_participation && this.fe_participation.comments ? this.fe_participation.comments
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        : [];
    },
    anyOpenModal() {
      return this.showEditModal
        || this.deleteModal;
    },
  },
  data() {
    return {
      moment,
      loadedPage: false,
      showEditModal: false,
      deleteModal: false,
      pageCount: 0,
      pageNr: 1,
      fe_participation: {},
      fe_participations: [],
      theKey: '',
      checkedKeywordItems: [],
      fe_media: null,
      rotate: 0,
      currentParticipationId: '',
      newComment: '',
      alsoBlock: false,
      cloneForm: {},
      editForm: {},
      editParticipationItems: [],
    };
  },
  methods: {
    async getAdminParticipation(query) {
      await this.$store
        .dispatch(`games/get${this.userType}GameParticipation`, { entityId: query || this.participationId });

      this.setJsonData();
    },
    setJsonData() {
      this.fe_media = this.participation.fe_media;
      this.fe_participation = JSON.parse(JSON.stringify(this.participation));
      delete this.fe_participation.game;
      delete this.fe_participation.user;

      for (let i = 0; i < this.fe_participation?.comments?.length; i++) {
        delete this.fe_participation.comments[i].user;
      }

      // this.setParticipationNone('something');
      this.$set(this.editForm, 'points', this.fe_participation.points);
      // this.$set(this.editForm, 'last_name', this.fe_participation.user.last_name);

      this.loadedPage = true;
      setTimeout(() => {
        const jsonTag = document.getElementById('json');
        jsonTag.innerHTML = this.syntaxHighlight(JSON.stringify(this.fe_participation, undefined, 2));
      }, 100);
    },
    setParticipationNone(key) {
      this.fe_participation[key] = this.fe_participation[key] === undefined ? null : this.fe_participation[key];
    },
    syntaxHighlight(json) {
      json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
        function (match) {
          let cls = "a-number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "a-key";
            } else {
              cls = "a-string";
            }
          } else if (/true|false/.test(match)) {
            cls = "a-boolean";
          } else if (/null/.test(match)) {
            cls = "a-null";
          } else if (match < 3000000000 && match > 1000000000) {
            match = moment.unix(match).format('YYYY-MM-DD HH:mm:ss ZZ');
          }
          return `<span class="${cls}">${match}</span>`;
        }
      );
    },
    editSaveParticipation() {
      this.$store
        .dispatch(`games/save${this.userType}GameParticipation`, {
          entityId: this.participationId,
          ...this.editForm,
          participation_items: this.editParticipationItems,
        }).then(() => {
          this.setAlert('Successfully saved the participation!');
          this.showEditModal = false;
          this.setJsonData();
        });
    },
    addAdminGameParticipationComment() {
      this.$store
      .dispatch(`games/add${this.userType}GameParticipationComment`, {
        comment: this.newComment,
        entityId: this.participationId
      })
      .then(() => {
        this.newComment = "";
        this.setJsonData();
      });
    },
    onEditConfirm(){
      this.editSaveParticipation();
    },
    popupLeft() {
      if (this.anyOpenModal) {
        return;
      }
      if (!this.participations || !this.participations.length) {
        this.setAlert('There are no participations to navigate through.');
        return;
      }
      for(let i = 0; i < this.participations.length; i++) {
        if (this.participations[i]._id === this.participation._id) {
          const prevIndex = i-1;
          if (prevIndex >= 0) {
            this.$router.replace({ params: { participationId: this.participations[prevIndex]._id } });
            this.getAdminParticipation(this.participations[prevIndex]._id);
          } else {
            this.setAlert('You are at the first participation');
          }
        }
      }
    },
    popupRight() {
      if (this.anyOpenModal) {
        return;
      }
      if (!this.participations || !this.participations.length) {
        this.setAlert('There are no participations to navigate through.');
        return;
      }
      for(let i = 0; i < this.participations.length; i++) {
        if (this.participations[i]._id === this.participation._id) {
          const nextIndex = i+1;
          if (nextIndex <= this.participations.length-1) {
            this.$router.replace({ params: { participationId: this.participations[nextIndex]._id } });
            this.getAdminParticipation(this.participations[nextIndex]._id);
          } else {
            this.setAlert('You are at the last participation');
          }
        }
      }
    },
    handleKeydown (itemId) {
      switch (itemId.keyCode) {
        case 37:
          this.popupLeft();
          break;
        case 39:
          this.popupRight();
          break;
        case 27:
          this.closeAllModals();
      }
    },
    closeAllModals() {
      this.showEditModal = false;
      this.deleteModal = false;
    },
    formatDate(unix) {
      return moment.unix(unix).format("YYYY-MM-DD HH:mm:ss"); //"YYYY-MM-DD HH:mm:ss ZZ"
    },
    async downloadImage(imageUrl) {
      this.openUrl(imageUrl, true);
    },
    deleteParticipation() {
      this.$store.dispatch(`games/delete${this.userType}GameParticipation`, {
        entityId: this.participation._id,
      }).then(() => {
        this.setAlert('Successfully deleted participation!');
        this.$router.go(-1);
      });
    },
    copyText(text, type) {
      navigator.clipboard.writeText(text).then(() => {
        this.setAlert(`Copied ${type} to clipboard`);
      }).catch(err => {
        console.log('copyText', err);
        this.setAlert(`Failed to copy ${type} to clipboard`);
      });
    },
    tryConsumerFlow() {
      this.$store.dispatch(`games/try${this.userType}ConsumerFlow`, {
        entityId: this.participation._id,
      }).then((token) => {
        this.openUrl(`${this.shortOrigin}/g/${this.game.game_type}/${this.game._id}/${this.game.enginio_game}?token=${token}`, true);
        // this.copyText(`${this.shortOrigin}/${this.game.market}/pv/${this.game._id}/${this.game.enginio_game}?token=${token}`, 'consumer participation URL');
        this.setAlert('Consumer flow started in another tab');
      });
    },
  },
  beforeMount () {
    window.addEventListener('keydown', this.handleKeydown, null);
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.a-string{
  color: darkorange;
}
.a-number{
  color: lightgreen;
}
.a-boolean{
  color: #7171ff;
}
.a-null{
  color: #c7291b;
}
.a-key{
  color: lightblue;
}

.additional-terms{
  height: 40px;
  .additional-terms-input {
    margin-left: 20px;
  }
}
</style>

<style lang="scss" scoped>
@import "./Participation.scss";
</style>
