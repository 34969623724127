import { render, staticRenderFns } from "./CompanyHome.vue?vue&type=template&id=dd36e970&scoped=true"
import script from "./CompanyHome.vue?vue&type=script&lang=js"
export * from "./CompanyHome.vue?vue&type=script&lang=js"
import style0 from "./CompanyHome.vue?vue&type=style&index=0&id=dd36e970&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd36e970",
  null
  
)

export default component.exports