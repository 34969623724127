<template>
  <div v-if="user && user._id" class="user-activations">
    <div class="-header">
      <h3>{{ transl.UserProfile.myactivations }}</h3>
      <div @click="$router.push({ name: 'UserProfile'}); $emit('back')" class="back-button--container --mobile">
        <iconComponent class="settings--back-button" symbol="arrowLeft" />
      </div>
    </div>


    <div v-if="user.active_offers.length" class="--active-now">
      <div class="pre-title-small">{{ transl.UserProfile.activenow }}</div>

      <div class="--container" v-for="(item, index) in user.active_offers" :key="index">
        <img v-if="item.media && item.media.length !== 0 && item.media[0].fileType.includes('image')"
             :src="item.media[0].url" alt="">
        <div v-else class="--icon-container">
          <iconComponent class="--icon" width="30px" symbol="rocket" fill="#EA7801" stroke="#EA7801" />
        </div>
        <div class="--activation-data">
          <div v-if="item.quick_text" class="body-2">{{ item.quick_text  }}</div>
          <div v-else class="body-2">{{ item.unique_name  }}</div>
        </div>
      </div>
    </div>

    <div v-else class="--no-active">
      <div class="pre-title-small">{{ transl.UserProfile.activenow }}</div>
      <div class="body-1">{{ transl.UserProfile.notactivated }}</div>
    </div>

    <div v-if="favoriteOffers.length > 0" class="--user-favorites">
      <div class="pre-title-small">{{ transl.UserProfile.favorites }}</div>

      <div class="--container" v-for="(item, index) in favoriteOffers" :key="index">
        <img v-if="item.media && item.media.length !== 0 && item.media[0].fileType.includes('image')"
             :src="item.media[0].url" alt="">
        <div v-else class="--icon-container">
          <iconComponent class="--icon" symbol="rocket" fill="#EA7801" stroke="#EA7801" />
        </div>
        <div class="body-2">{{ item.quick_text }}</div>
        <iconComponent class="--icon" symbol="heart" fill="#EA7801" stroke="#EA7801" />
      </div>
    </div>

    <div v-else class="--no-favorites">
      <div class="body-1">{{ transl.UserProfile.nofavorite }}</div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import IconComponent from '../stories/components/IconComponent/IconComponent.vue';

export default {
  name: 'UserActivations',
  components: {
    IconComponent,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.setPageTitle();
  },
  watch: {},
  computed: {
    ...mapGetters(['user', 'favoriteOffers', 'userStatus']),
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/UserActivations";
</style>
