/* eslint-disable no-shadow, no-param-reassign */
import communicationApi from '../../api/communication.api';

// initial state
const state = {
  communication: {},
  communications: [],
};

// getters
const getters = {
  communication: state => state.communication,
  communications: state => state.communications,
};

// actions
const actions = {
  findAdminCommunications({ commit }, payload) {
    return communicationApi.findAdminCommunications(payload)
      .then((response) => {
        commit('COMMUNICATIONS', response.communications);
      });
  },

  addAdminCommunication({ commit }, payload) {
    return communicationApi.addAdminCommunication(payload)
      .then((response) => {
        commit('ADD_COMMUNICATION', response.communication);
        commit('COMMUNICATION', response.communication);
      });
  },

  getAdminCommunication({ commit }, payload) {
    return communicationApi.getAdminCommunication(payload)
      .then((response) => {
        commit('COMMUNICATION', response.communication);
      });
  },

  saveAdminCommunication({ commit }, payload) {
    return communicationApi.saveAdminCommunication(payload)
      .then((response) => {
        commit('COMMUNICATION', response.communication);
      });
  },

  importAdminContacts({ commit }, payload) {
    return communicationApi.importAdminContacts(payload)
      .then((response) => {
        commit('COMMUNICATION', response.communication);
        return response;
      });
  },

  sendAdminCommunicationEmail({ commit }, payload) {
    return communicationApi.sendAdminCommunicationEmail(payload)
      .then((response) => {
        commit('COMMUNICATION', response.communication);
      });
  }
};

// mutations
const mutations = {
  COMMUNICATIONS(state, communications) {
    state.communications = communications;
  },
  ADD_COMMUNICATION(state, communication) {
    state.communications.push(communication);
  },
  COMMUNICATION(state, communication) {
    state.communication = communication;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
