import { render, staticRenderFns } from "./LanguagePickerView.vue?vue&type=template&id=9b359292&scoped=true"
import script from "./LanguagePickerView.vue?vue&type=script&lang=js"
export * from "./LanguagePickerView.vue?vue&type=script&lang=js"
import style0 from "./LanguagePickerView.vue?vue&type=style&index=0&id=9b359292&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b359292",
  null
  
)

export default component.exports